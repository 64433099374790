import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import ServicesData from '../../../content/services-content.json';

const Services = (props) => {
  return (
    <Layout bodyClass="page-services">
      <SEO title="Services" />
      <div className="intro mt-5 mt-lg-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Our Services</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
        {ServicesData.content.map((data, index) => {
            return <div key={`content_item_${index}`} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <p className="service-title">{data.title}</p>
                  <p>{data.description}</p>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Services;
